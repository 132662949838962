import React from 'react'

import Layout from '../components/layout/layout'
import PageHeader from '../components/layout/page-header'

const NotFoundPage = () => (
  <Layout>
    <PageHeader>NOT FOUND</PageHeader>

    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
